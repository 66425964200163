import logi1 from './img/logi/logi_1-min.jpg'
import logi2 from './img/logi/logi_2-min.jpg'
import logi3 from './img/logi/logi_3-min.JPG'
import logi4 from './img/logi/logi_4-min.jpg'
import logi5 from './img/logi/logi_5-min.jpg'
import logi6 from './img/logi/logi_6-min.jpg'
import logi7 from './img/logi/logi_7-min.jpg' 

import durivs1 from './img/durvis/durvis-1-min.jpg'
import durivs2 from './img/durvis/durvis-2-min.jpg'
import durivs3 from './img/durvis/durvis-3-min.jpg'
import durivs4 from './img/durvis/durvis-4-min.jpg'
import durivs5 from './img/durvis/durvis-5-min.jpg'
import durivs6 from './img/durvis/durvis-6-min.jpg'
import durivs7 from './img/durvis/durvis-7-min.jpg'
import durivs8 from './img/durvis/durvis-8-min.jpg'
import durivs9 from './img/durvis/durvis-9-min.jpg'
import durivs10 from './img/durvis/durvis-10-min.jpg'
import durivs11 from './img/durvis/durvis-11-min.jpg'
import durivs12 from './img/durvis/durvis-12-min.jpg'
import durivs15 from './img/durvis/durvis-15-min.jpg'
import durivs16 from './img/durvis/durvis-16-min.jpg'
import durivs17 from './img/durvis/durvis-17-min.jpg'
import durivs18 from './img/durvis/durvis-18-min.jpg'
import durivs19 from './img/durvis/durvis-19-min.jpg'
import durivs20 from './img/durvis/durvis-20-min.jpg'

import sienas1 from './img/sienas/sienas1-min.jpg'
import sienas2 from './img/sienas/sienas2-min.jpg'
import sienas3 from './img/sienas/sienas3-min.jpg'
import sienas4 from './img/sienas/sienas4-min.jpg'
import sienas5 from './img/sienas/sienas5-min.jpg'
import sienas6 from './img/sienas/sienas6-min.jpg'
import sienas7 from './img/sienas/sienas7-min.jpg'
import sienas8 from './img/sienas/sienas8-min.jpg'
import sienas9 from './img/sienas/sienas9-min.jpg'
import sienas10 from './img/sienas/sienas10-min.jpg'

import lampas1 from './img/lampas/lampas1-min.jpg'
import lampas2 from './img/lampas/lampas2-min.jpg'
import lampas3 from './img/lampas/lampas3-min.jpeg'
import lampas4 from './img/lampas/lampas4-min.jpg'
import lampas5 from './img/lampas/lampas5-min.jpg'
import lampas6 from './img/lampas/lampas6-min.jpg'
import lampas7 from './img/lampas/lampas7-min.jpg'
import lampas8 from './img/lampas/lampas8-min.jpg'
import lampas9 from './img/lampas/lampas9-min.jpg'
import lampas11 from './img/lampas/lampas11-min.jpeg'

import kausi1 from './img/kausi/kausi1-min.jpg'
import kausi2 from './img/kausi/kausi2-min.jpg'
import kausi3 from './img/kausi/kausi3-min.jpg'
import kausi4 from './img/kausi/kausi4-min.jpg'
import kausi5 from './img/kausi/kausi5-min.jpg'
import kausi6 from './img/kausi/kausi6-min.jpg'
import kausi7 from './img/kausi/kausi7-min.jpg'
import kausi8 from './img/kausi/kausi8-min.jpg'
import kausi9 from './img/kausi/kausi9-min.jpg'
import kausi10 from './img/kausi/kausi10-min.jpg'
import kausi11 from './img/kausi/kausi11-min.jpg'
import kausi12 from './img/kausi/kausi12-min.jpg'
import kausi13 from './img/kausi/kausi13-min.jpg'
import kausi14 from './img/kausi/kausi14-min.jpg'
import kausi15 from './img/kausi/kausi15-min.jpg'
import kausi16 from './img/kausi/kausi16-min.jpg'
import kausi17 from './img/kausi/kausi17-min.jpg'

import process1 from './img/process/process1-min.jpg'
import process2 from './img/process/process2-min.jpg'
import process3 from './img/process/process3-min.jpg'
import process4 from './img/process/process4-min.jpg'
import process5 from './img/process/process5-min.jpg'


import suveniri1 from './img/suveniri/suveniri1-min.jpg'
import suveniri2 from './img/suveniri/suveniri2-min.jpg'
import suveniri3 from './img/suveniri/suveniri3-min.jpg'


export const interior = [
  {
    src: logi1,
    width: 10,
    height: 10
  },
  {
    src: logi2,
    width: 2,
    height: 3
  },
  {
    src: logi3,
    width: 3,
    height: 4
  },
  {
    src: logi4,
    width: 2,
    height: 2
  },
  {
    src: logi5,
    width: 10,
    height: 10
  },
  {
    src: logi6,
    width: 4,
    height: 3
  },
  {
    src: logi7,
    width: 3,
    height: 4
  }
];

export const durvis = [
  {
    src: durivs1,
    width: 10,
    height: 10
  },
  {
    src: durivs2,
    width: 8,
    height: 10
  },
  {
    src: durivs3,
    width: 7,
    height: 10
  },
  {
    src: durivs4,
    width: 10,
    height: 10
  },
  {
    src: durivs5,
    width: 10,
    height: 10
  },
  {
    src: durivs6,
    width: 8,
    height: 10
  },
  {
    src: durivs7,
    width: 10,
    height: 10
  },
  {
    src: durivs8,
    width: 10,
    height: 10
  },
  {
    src: durivs9,
    width: 10,
    height: 10
  },
  {
    src: durivs10,
    width: 8,
    height: 10
  },
  {
    src: durivs11,
    width: 8,
    height: 10
  },
  {
    src: durivs12,
    width: 10,
    height: 10
  },
  {
    src: durivs15,
    width: 8,
    height: 10
  },
  {
    src: durivs16,
    width: 8,
    height: 10
  },
  {
    src: durivs17,
    width: 10,
    height: 10
  },
  {
    src: durivs18,
    width: 10,
    height: 10
  },
  {
    src: durivs19,
    width: 10,
    height: 10
  },
  {
    src: durivs20,
    width: 10,
    height: 10
  }
]

export const sienas = [
  {
    src: sienas1,
    width: 10,
    height: 10
  },
  {
    src: sienas2,
    width: 10,
    height: 10
  },
  {
    src: sienas3,
    width: 10,
    height: 10
  },
  {
    src: sienas4,
    width: 8,
    height: 10
  },
  {
    src: sienas5,
    width: 10,
    height: 10
  },
  {
    src: sienas6,
    width: 10,
    height: 8
  },
  {
    src: sienas7,
    width: 10,
    height: 10
  },
  {
    src: sienas8,
    width: 10,
    height: 5
  },
  {
    src: sienas9,
    width: 10,
    height: 10
  },
  {
    src: sienas10,
    width: 10,
    height: 5
  }
]

export const lampas = [
  {
    src: lampas1,
    width: 10,
    height: 10
  },
  {
    src: lampas2,
    width: 10,
    height: 10
  },
  {
    src: lampas3,
    width: 8,
    height: 10
  },
  {
    src: lampas11,
    width: 10,
    height: 8
  },
  {
    src: lampas4,
    width: 5,
    height: 7
  },
  {
    src: lampas5,
    width: 8,
    height: 10
  },
  {
    src: lampas6,
    width: 10,
    height: 10
  },
  {
    src: lampas7,
    width: 10,
    height: 10
  },
  {
    src: lampas8,
    width: 7,
    height: 10
  },
  {
    src: lampas9,
    width: 8,
    height: 10
  }
]

export const kausi = [
  {
    src: kausi1,
    width: 10,
    height: 8
  },
  {
    src: kausi2,
    width: 10,
    height: 8
  },
  {
    src: kausi3,
    width: 10,
    height: 6
  },
  {
    src: kausi4,
    width: 10,
    height: 10
  },
  {
    src: kausi5,
    width: 8,
    height: 10
  },
  {
    src: kausi6,
    width: 7,
    height: 10
  },
  {
    src: kausi7,
    width: 5,
    height: 10
  },
  {
    src: kausi8,
    width: 10,
    height: 5
  },
  {
    src: kausi9,
    width: 10,
    height: 8
  },
  {
    src: kausi10,
    width: 10,
    height: 8
  },
  {
    src: kausi11,
    width: 10,
    height: 9
  },
  {
    src: kausi12,
    width: 10,
    height: 10
  },
  {
    src: kausi13,
    width: 8,
    height: 10
  },
  {
    src: kausi14,
    width: 10,
    height: 10
  },
  {
    src: kausi15,
    width: 5,
    height: 10
  },
  {
    src: kausi16,
    width: 10,
    height: 6
  },
  {
    src: kausi17,
    width: 10,
    height: 6
  }
]

export const process = [
  {
    src: process5,
    width: 10,
    height: 8
  },
  {
    src: process4,
    width: 10,
    height: 8
  },
  {
    src: process1,
    width: 10,
    height: 8
  },
  {
    src: process2,
    width: 10,
    height: 8
  },
  {
    src: process3,
    width: 10,
    height: 7
  }
]

export const suveniri = [
  {
    src: suveniri1,
    width: 10,
    height: 8
  },
  {
    src: suveniri2,
    width: 10,
    height: 8
  },
  {
    src: suveniri3,
    width: 10,
    height: 8
  }
]