import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container'

const NotFound = () =>{
    const { t } = useTranslation();

    return(
        <Container>
            <h1>{t('not_found')}</h1>
        </Container>
    )
}

export default NotFound;