import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import icon from './img/icon.png'
import { interior, kausi, process, durvis, sienas, lampas, suveniri } from "./photos";
import { LinkContainer } from "react-router-bootstrap";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const HeaderH1 = styled.h1`
    text-align: center;
    font-size: 2rem;
    margin-top: 4%;
`

const  Header = ({galleryHeader, galleryPhotos}) =>{
    const { t } = useTranslation();
    let initialLocation;
    if (window.location.pathname == "/") {
        initialLocation = "/home";
    }else{
        initialLocation = window.location.pathname;
    }

    const [activeIndex, setState] = useState(initialLocation);
    const handleSelect = (eventKey) => {
        setState(eventKey);

        switch(eventKey){
            case "/vitrazas-interjera":
                galleryHeader(t('menu_window'));
                galleryPhotos(interior);
                break;
            case "/vitrazas-kausi":
                galleryHeader(t('menu_awards'));
                galleryPhotos(kausi);
                break;
            case "/vitrazas-process":
                galleryHeader(t('menu_process'));
                galleryPhotos(process);
                break;  
            case "/vitrazas-durvis":
                galleryHeader(t('menu_doors'));
                galleryPhotos(durvis);
                break;  
            case "/vitrazas-sienas":
                galleryHeader(t('menu_walls'));
                galleryPhotos(sienas);
                break;   
            case "/vitrazas-lampas":
                galleryHeader(t('menu_lamps'));
                galleryPhotos(lampas);
                break; 
            case "/vitrazas-suveniri":
                galleryHeader(t('menu_suvenirs'));
                galleryPhotos(suveniri);
                break; 
        }
    };
    
    const handleChange = (e) =>{
        i18next.changeLanguage(e.target.value);
        switch(activeIndex){
            case "/vitrazas-interjera":
                galleryHeader(t('menu_window'));
                break;
            case "/vitrazas-kausi":
                galleryHeader(t('menu_awards'));
                break;
            case "/vitrazas-process":
                galleryHeader(t('menu_process'));
                break;  
            case "/vitrazas-durvis":
                galleryHeader(t('menu_doors'));
                break;  
            case "/vitrazas-sienas":
                galleryHeader(t('menu_walls'));
                break;   
            case "/vitrazas-lampas":
                galleryHeader(t('menu_lamps'));
                break; 
            case "/vitrazas-suveniri":
                galleryHeader(t('menu_suvenirs'));
                break; 
        }
    }
    return(
        <div>
            <LinkContainer to="/home" exact><HeaderH1>MARGARITAS VITRĀŽAS</HeaderH1></LinkContainer>
            <Navbar collapseOnSelect expand="sm" className="mt-5">
            <Navbar.Brand as={Link} to="/home">
                <img 
                alt="Margaritas Vitrāžas"
                width="50"
                height="50"
                id="icon"
                className="d-inline-block align-top"
                src={icon}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ml-auto" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                <Nav variant="pills" activeKey={activeIndex} onSelect={handleSelect} key={initialLocation}>
                        <Nav.Item className="mr-md-5" href="/home">
                            <LinkContainer to="/home" exact>
                                <Nav.Link className="process-as-link">
                                    {t('menu_home')}
                                </Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    {/* <Link to="/vitazas"> */}
                        <NavDropdown title={t('menu_glass')} id="nav-dropdown" className="mr-md-5">
                            <LinkContainer to="/vitrazas" exact>
                                <NavDropdown.Item eventKey="/vitrazas-interjera">
                                    {t('menu_window')}
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/vitrazas">
                                <NavDropdown.Item eventKey="/vitrazas-durvis">
                                    {t('menu_doors')}
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/vitrazas">
                                <NavDropdown.Item eventKey="/vitrazas-sienas">
                                    {t('menu_walls')}
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/vitrazas">
                                <NavDropdown.Item eventKey="/vitrazas-lampas">
                                    {t('menu_lamps')}
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/vitrazas">
                                <NavDropdown.Item eventKey="/vitrazas-kausi">
                                    {t('menu_awards')}
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/vitrazas">
                                <NavDropdown.Item eventKey="/vitrazas-suveniri">
                                    {t('menu_suvenirs')}
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/vitrazas">
                                <NavDropdown.Item eventKey="/vitrazas-process">
                                    {t('menu_process')}
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                    {/* </Link> */}
                        <Nav.Item className="mr-md-5" >
                            <LinkContainer to="/about" exact>
                                <Nav.Link className="process-as-link" active={false} eventKey="/about" title="Item">
                                    {t('about_me')}
                                </Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item className="mr-md-5">
                            <LinkContainer to="/order" exact>
                                <Nav.Link className="process-as-link" active={false} eventKey="/order" title="Item">
                                    {t('order_vitraz')}
                                </Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    <select as="select" onChange={handleChange}>
                        <option value="lv" defaultValue>LV</option>
                        <option value="en">EN</option>
                        <option value="ru">RU</option>
                    </select>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Header;