import React, { useState, useCallback } from "react";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import door from './img/main/main-door-min.jpg'
import lamp from './img/main/main-lamp-min.jpg'
import photo from './img/main/main-photo-min.jpg'
import window from './img/main/main-window-min.jpg'

const  Home = () =>{
    const [toggler, setToggler] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
   
    const openLightbox = useCallback((event, { photo, index }) => {
        setToggler(!toggler);
        setCurrentImage(index);
    }, [toggler, currentImage]);
  
    return(
        <Container fluid>
            <Row className="mt-5">
                <Col md={4} sm={12} className="h-25">
                    <img className="img-fluid home-img-cover" src={lamp}/>
                </Col>
                <Col md={4} sm={12}>
                    <img className="img-fluid home-img-cover" src={door}/>
                </Col>
                <Col md={4} sm={12}>
                    <Row>
                        <Col md={12} sm={12}>
                            <img id="home-img-small-1" className="img-fluid" src={photo}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={12}>
                            <img id="home-img-small-2" className="img-fluid" src={window}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Home;