import React, { useState, useCallback } from "react";
import FsLightbox from 'fslightbox-react';
import Gallery from 'react-photo-gallery';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Vitrazas = ({galleryHeader, galleryPhotos}) =>{
    const [toggler, setToggler] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
   
    const openLightbox = useCallback((event, { photo, index }) => {
        setToggler(!toggler);
        setCurrentImage(index);
    }, [toggler, currentImage]);

    return(
        <Container fluid className="mb-5">
            <Row className="mt-5">
                <Col>
                    <h1>{galleryHeader}</h1>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={{span: 6, offset: 3}} sm={{ span: 12}}>
                    <Gallery photos={galleryPhotos} onClick={openLightbox} />
                    <FsLightbox
                        toggler={toggler}
                        sources={
                            galleryPhotos.map((img=>
                            img.src
                        ))
                        }
                        sourceIndex={currentImage}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Vitrazas;