import React, { useState } from "react";
import './App.css';
import Header from './Header.js'
import Footer from './Footer.js'
import Home from './Home.js'
import Vitrazas from './Vitrazas.js'
import { interior } from "./photos";
import About from './About.js'
import Order from './Order.js'
import NotFound from './NotFound.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  const [galleryHeader, setGalleryHeader] = useState(t('menu_window'));
  const [galleryPhotos, setGalleryPhotos] = useState(interior);

  return (
    <Router>
      <div className="App">
        <Header galleryHeader={setGalleryHeader} galleryPhotos={setGalleryPhotos}/>
        <Switch>
          <Route path={["/home","/"]} exact component={Home}/>
          <Route path="/vitrazas" exact render={() => (
            <Vitrazas galleryHeader={galleryHeader} galleryPhotos={galleryPhotos} />
          )}/>
          <Route path="/about" exact component={About}/>
          <Route path="/order" exact component={Order}/>
          <Route component={NotFound}/>
        </Switch>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
