import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Lamp from './img/lamp-min.jpg'
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const  Order = () =>{
    const API_PATH = 'https://mvitraz.com/contact/index.php';
    const { t } = useTranslation();
    const [state, setState] = useState({
        name: '',
        topic: '',
        email: '',
        message: '',
        mailSent: false,
        error: null
    })
    const [validated, setValidated] = useState(false);
    const [isEmailError, setEmailError] = useState(false);

    const checkEmail = () =>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(state.email).toLowerCase());
    }

    const handleFormSubmit = (event) =>{
        const form = event.currentTarget;
        let noErrors = true;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false) {
            noErrors = false;
            event.stopPropagation();
        }
        if(checkEmail() === false){
            noErrors = false;
            setEmailError(true);
            event.stopPropagation();
        }
        setEmailError(false);
        if(noErrors){
            axios({
                method: 'post',
                url: `${API_PATH}`,
                headers: { 'content-type': 'application/json' },
                data: state
              })
                .then(result => {
                  setState({
                    mailSent: result.data.sent
                  })
                })
                .catch(error => setState({ error: error.message }));
        }

    }
    return(
        <Container fluid>
            <Row className="mt-5 order-background">
                <Col md={{ span: 4, offset: 1, order: "first"}} xs={{ span: 12, order: "last"}}>
                    <h2 className="mt-5">{t('order_vitraz')}</h2>
                    <p className="mt-3">{t('order_email')}: pelegrina@inbox.lv</p>
                    <p>{t('order_phone')} +371 26764480</p>
                    <Form className="mt-5"  method="post" noValidate validated={validated}>
                    <Row>
                        <Col>
                        <Form.Control
                            required 
                            placeholder={t('order_name')}
                            value={state.name || ""}
                            onChange={e => setState({...state, name: e.target.value})}/>
                        </Col>
                        <Col>
                        <Form.Control 
                            required
                            type="email"
                            placeholder={t('order_email')}
                            value={state.email || ""}
                            onChange={e => setState({...state, email: e.target.value})}
                            className={`${isEmailError ? "isEmailError": ""}`}/>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Form.Control
                                required
                                placeholder={t('order_theme')}
                                value={state.topic || ""}
                                onChange={e => setState({...state, topic: e.target.value})}/>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Form.Label className="float-left">{t('order_letter')}</Form.Label>
                            <Form.Control 
                                required
                                as="textarea"
                                value={state.message || ""}
                                onChange={e => setState({...state, message: e.target.value})}
                                rows={3} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type="submit" onClick={e => handleFormSubmit(e)} className="mt-3 button-lg" variant="primary" size="md">{t('order_send')}</Button>{' '}
                        </Col>
                    </Row>
                    <div>
                    {state.mailSent &&
                        <div id="orderThanks">{t('order_thanks')}</div>
                    }
                    </div>
                    </Form>
                </Col>
                <Col md={{ span: 6, offset: 1}} xs={{ span: 12, order: "first"}}>
                    <img className="img-fluid" src={Lamp} alt="Lamp stained-glass"/>
                </Col>
            </Row>
        </Container>
        
    )
}

export default Order;