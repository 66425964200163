import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Margarita from './img/img_in_frame.png'
import { useTranslation } from 'react-i18next';

const  About = () =>{
    const { t } = useTranslation();

    return(
        <Container fluid className="about-background">
            <Row className="mt-5">
                <Col className="mt-5">
                    <h2>{t('about_me')}</h2>
                </Col>
            </Row>
            <Row className="mt-5 pb-5">
                <Col md={{ span: 4, offset: 1 }}>
                    <img className="img-fluid" src={Margarita}></img>
                </Col>
                <Col md={6}>
                    <p id="about-description" className="text-justify">
                    {t('about_desc')}
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default About;