import { useTranslation } from 'react-i18next';

const Footer = () =>{
    const { t } = useTranslation();

    return(
        <footer>
            <p className="mt-3"><small>© 2021 Margaritas vitrāžas. {t('created_by')} <a href="https://www.facebook.com/vladislavs.jerins/"> Vladislavs Jerins</a></small></p>
        </footer>
    )
}

export default Footer